const preloadAsset = (src, isAudio = false) => {
  const assetLink = document.createElement('link');
  assetLink.rel = 'preload';
  assetLink.href = src;
  assetLink.as = isAudio ? 'audio' : 'image';
  assetLink.type = isAudio ? 'audio/mp3' : 'image/svg+xml';
  return document.head.appendChild(assetLink);
}

export default preloadAsset;
