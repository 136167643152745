import { skillListItemClassSuffix } from "../RoundUpDataLayoutHelper";

const skillsUpcomingListedSlide = ({skills_upcoming, kid_name, ordering}) => {

  if (!kid_name || !skills_upcoming || !skills_upcoming.length > 0) return null;

  const first = skills_upcoming[0] ? skills_upcoming[0] : '';
  const second = skills_upcoming[1] ? skills_upcoming[1] : '';
  const third = skills_upcoming[2] ? skills_upcoming[2] : '';

  const first_class = first.length > 1 ? `body-strong_star${skillListItemClassSuffix(first)}` : "body-strong";
  const second_class = second.length > 1 ? `body-strong-alt_star${skillListItemClassSuffix(second)}` : "body-strong-alt";
  const third_class = third.length > 1 ? `body-strong_star${skillListItemClassSuffix(third)}` : "body-strong";

  const skills_upcoming_roundup_data_specific = {
    "order": ordering.skills_upcoming,
    "name": "skills_upcoming",
    "tracking": "skills_upcoming",
    "colorOption": 1,
    "sections": {
      1: [
        {"title-large": "Skills"},
        {"head-small": "Upcoming"},
        {"body-highlight": `Here's what's coming next for <span>${kid_name}</span>...`},
      ],
      2: [],
      3: [],
      4: [
        {[first_class]: `${first}`},
        {[second_class]: `${second}`},
        {[third_class]: `${third}`},
      ],
      5: [
        {"subTitle-emphasis-2": `Exciting next steps!`}
      ] 
    }
  };

  return skills_upcoming_roundup_data_specific;
}

export default skillsUpcomingListedSlide;
