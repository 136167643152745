import bassBand from '../components/RoundUp/assets/kid_assets/bass/Band-Bass.svg';
import bassConcert from '../components/RoundUp/assets/kid_assets/bass/Concert-Bass.svg';
import bassSolo from '../components/RoundUp/assets/kid_assets/bass/Solo-Bass.svg';

import guitarBand from '../components/RoundUp/assets/kid_assets/guitar/Band-Guitar.svg';
import guitarConcert from '../components/RoundUp/assets/kid_assets/guitar/Concert-Guitar.svg';
import guitarSolo from '../components/RoundUp/assets/kid_assets/guitar/Solo-Guitar.svg';

import keyboardBand from '../components/RoundUp/assets/kid_assets/keyboard/Band-Keyboard.svg';
import keyboardConcert from '../components/RoundUp/assets/kid_assets/keyboard/Concert-Keyboard.svg';
import keyboardSolo from '../components/RoundUp/assets/kid_assets/keyboard/Solo-Keyboard.svg';

import vocalsBand from '../components/RoundUp/assets/kid_assets/vocals/Band-Vocal.svg';
import vocalsConcert from '../components/RoundUp/assets/kid_assets/vocals/Concert-Vocal.svg';
import vocalsSolo from '../components/RoundUp/assets/kid_assets/vocals/Solo-Vocal.svg';

import drumsBand from '../components/RoundUp/assets/kid_assets/drums/Band-Drums.svg';
import drumsConcert from '../components/RoundUp/assets/kid_assets/drums/Concert-Drums.svg';
import drumsSolo from '../components/RoundUp/assets/kid_assets/drums/Solo-Drums.svg';

import preloadAsset from './AssetUtils';

export const preloadInstrumentAssets = (instrument) => {
  if (instrument === 'vocals') {
    preloadAsset(vocalsBand);
    preloadAsset(vocalsConcert);
    preloadAsset(vocalsSolo);
  }

  if (instrument === 'guitar') {
    preloadAsset(guitarBand);
    preloadAsset(guitarConcert);
    preloadAsset(guitarSolo);
  }

  if (instrument === 'drums') {
    preloadAsset(drumsBand);
    preloadAsset(drumsConcert);
    preloadAsset(drumsSolo);
  }

  if (instrument === 'keyboard') {
    preloadAsset(keyboardBand);
    preloadAsset(keyboardConcert);
    preloadAsset(keyboardSolo);
  }

  if (instrument === 'bass') {
    preloadAsset(bassBand);
    preloadAsset(bassConcert);
    preloadAsset(bassSolo);
  }
}


export const kidInstrumentAssets = {
  "bass": {
    "band": bassBand,
    "concert": bassConcert,
    "solo": bassSolo
  },
  "drums": {
    "band": drumsBand,
    "concert": drumsConcert,
    "solo": drumsSolo
  },
  "guitar": {
    "band": guitarBand,
    "concert": guitarConcert,
    "solo": guitarSolo
  },
  "keyboard": {
    "band": keyboardBand,
    "concert": keyboardConcert,
    "solo": keyboardSolo
  },
  "vocals": {
    "band": vocalsBand,
    "concert": vocalsConcert,
    "solo": vocalsSolo
  }
}
