import song from '../../components/RoundUp/assets/song.svg';
import trinity_info_thumbnail from '../../components/RoundUp/assets/trinity_info_video.png';
import trinityBadge from '../../components/RoundUp/assets/trinity_badge.svg';
import iconPlay from '../../components/RoundUp/assets/icon_play.svg';
import sparkles from '../../components/RoundUp/assets/sparkle.svg';
import report from '../../components/RoundUp/assets/report.svg';
import lightRig from '../../components/RoundUp/assets/Light-Rig.svg'

import preloadAsset from '../../utils/AssetUtils';
import { concertName, instrumentToRole } from '../../consts/roundup';
import { preloadInstrumentAssets } from "../../utils/RoundUpAssets";

import introSlide from './slideOptions/introOption';
import bandRoleSlide from './slideOptions/bandRoleOption';
import lessonAttendanceSlide from './slideOptions/lessonAttendanceOption';
import concertAttendanceSlide from './slideOptions/concertAttendanceOption';
import currentSongSlide from './slideOptions/currentSongOption';
import skillsCompleteListedSlide from './slideOptions/skillsCompleteListedOption';
import skillsInProgressSlide from './slideOptions/skillsInProgressOption';
import skillsUpcomingListedSlide from './slideOptions/skillsUpcomingListedOption';
import skillsCompleteCountSlide from './slideOptions/skillsCompleteCountOption';
import trinityEnrollmentSlide from './slideOptions/trinityEnrollmentOption';
import trinityInfoSlide from './slideOptions/trinityInfoOption';
import mediaSlide from './slideOptions/mediaOption';
import upcomingConcertSlide from './slideOptions/upcomingConcertOption';
import outroSlide from './slideOptions/outroOption';

const ordering = {
  intro: 1,
  band_role: 2,
  media_1: 3,
  lesson_attendance: 4,
  concert_attendance: 5,
  current_song: 6,
  skills_completed_since_last_roundup: 7,
  skills_in_progress: 8,
  skills_upcoming: 9,
  skills_completed: 10,
  media_2: 11,
  trinity_enrolled: 12,
  trinity_info: 13,
  media_3: 14,
  upcoming_concert: 15,
  outro: 16
}

const translateToSlide = (response) => {
  const {
    data
  } = response;

  if (!data) return null;

  const {
    id,
    attributes,
  } = data;
  
  const {
    ac_year,
    ac_year_period,
    latest_band_media
  } = attributes

  const attributeData = attributes.data;

  const {
    kid_name,
    instrument,
    band_name,
    lessons_count,
    concerts_count,
    skills_completed,
    skills_completed_since_last_roundup,
    skills_in_progress,
    skills_upcoming,
    song_title,
    song_artist,
    next_concert_planned,
    trinity_enrolled,
    stage_progress_percent,
    current_trinity_stage
  } = attributeData;

  if (!kid_name) return null;
  
  const band_role = instrument && instrumentToRole[instrument];
  const hasDateInfo = ac_year_period && ac_year;
  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""
  const date = hasDateInfo && capitalize(`${ac_year_period}`);
  const nextConcertName = hasDateInfo && concertName[ac_year_period];

  const slides = [];
  const slideDesignators = [];

  // PRELOAD ASSETS
  if (latest_band_media) {
    latest_band_media.forEach((media) => {
      if (media.media_type === 'photo') {
        const img = new Image();
        img.src = media.photo_url;
      }
    });
  };

  if (trinity_enrolled) {
    const imgTrinity_info_thumbnail = new Image();
    imgTrinity_info_thumbnail.src = trinity_info_thumbnail;
    preloadAsset(trinityBadge);
  }

  if (song_title && song_artist) {
    preloadAsset(song)
  }

  if (latest_band_media) {
    preloadAsset(iconPlay)
    preloadAsset(report)
  }

  preloadInstrumentAssets(instrument)
  preloadAsset(lightRig)
  preloadAsset(sparkles)

  // INTRO
  const intro_slide = introSlide({date, band_role, kid_name, ordering, instrument})
  if (intro_slide) {
    slides.push(intro_slide);
  }

  // BAND ROLE
  const band_role_slide = bandRoleSlide({band_name, band_role, kid_name, ordering, instrument})
  if (band_role_slide) {
    slides.push(band_role_slide);
  }
  
  // LESSON ATTENDANCE
  const lesson_attendance_slide = lessonAttendanceSlide({lessons_count, kid_name, ordering, instrument})
  if (lesson_attendance_slide) {
    slides.push(lesson_attendance_slide);
  }

  // CONCERT ATTENDANCE
  const concert_attendance_slide = concertAttendanceSlide({concerts_count, kid_name, ordering, instrument})
  if (concert_attendance_slide) {
    slides.push(concert_attendance_slide);
  }

  // CURRENT SONG
  const current_song_slide = currentSongSlide({song_title, song_artist, kid_name, ordering})
  if (current_song_slide) {
    slides.push(current_song_slide);
  }

  // SKILLS COMPLETE (since last roundup) - LISTED 
  const skills_complete_listed_slide = skillsCompleteListedSlide({skills_completed_since_last_roundup, kid_name, ordering})
  if (skills_complete_listed_slide) {
    slides.push(skills_complete_listed_slide);
  }

  // SKILLS IN PROGRESS
  const skills_in_progress_slide = skillsInProgressSlide({skills_in_progress, kid_name, ordering})
  if (skills_in_progress_slide) {
    slides.push(skills_in_progress_slide);
  }

  // SKILLS UPCOMING - LISTED 
  const skills_upcoming_listed_slide = skillsUpcomingListedSlide({skills_upcoming, kid_name, ordering})
  if (skills_upcoming_listed_slide) {
    slides.push(skills_upcoming_listed_slide);
  }

  // SKILLS COMPLETED (accumulative) - COUNT
  const skills_complete_count_slide = skillsCompleteCountSlide({skills_completed, kid_name, ordering})
  if (skills_complete_count_slide) {
    slides.push(skills_complete_count_slide);
  }

  // TRINITY ENROLLMENT
  const trinity_enrollment_slide = trinityEnrollmentSlide({trinity_enrolled, stage_progress_percent, kid_name, ordering, current_trinity_stage})
  if (trinity_enrollment_slide) {
    slides.push(trinity_enrollment_slide);
  }

  // TRINITY INFO
  const trinity_info_slide = trinityInfoSlide({trinity_enrolled, kid_name, ordering})
  if (trinity_info_slide) {
    slides.push(trinity_info_slide);
  }

  // UPCOMING CONCERT
  const upcoming_concert_slide = upcomingConcertSlide({next_concert_planned, nextConcertName, instrument, kid_name, ordering})
  if (upcoming_concert_slide) {
    slides.push(upcoming_concert_slide);
  }

  // MEDIA
  const media_slides = mediaSlide({latest_band_media, kid_name, ordering})
  if (media_slides) {
    media_slides.forEach((slide) => slides.push(slide))
  }
   
  // OUTRO
  const outro_slide = outroSlide({instrument, kid_name, ordering})
  if (outro_slide) {
    slides.push(outro_slide);
  }
  
  slides.sort((a,b) => a.order - b.order)
  slides.forEach((slide) => {
    slideDesignators.push(slide.tracking)
  })

  return  {
    id,
    instrument,
    slides,
    tracking: {
      slides_count: slides.length,
      slide_designators: slideDesignators
    }
  }
}

export default translateToSlide;
