const trinityEnrollmentSlide = ({trinity_enrolled, stage_progress_percent, kid_name, ordering, current_trinity_stage}) => {

  if (!kid_name || !stage_progress_percent || !current_trinity_stage || !trinity_enrolled) return null;
  const stage = current_trinity_stage && current_trinity_stage.split('Music Performance in Bands')[1];

  if (stage_progress_percent <= 33) {
    const trinity_progress_low = {
      "order": ordering.trinity_enrolled,
      "name": "trinity_progress_low",
      "tracking": "trinity_progress_low",
      "colorOption": 2,
      "sections": {
        1: [
          {"title-strong-alt": 'Awesome effort!'}
        ],
        2: [],
        3: [
          {"progress": stage_progress_percent},
        ],
        4: [],
        5: [
          {"body": `<span>${kid_name}</span> has started working towards their <span>${stage}</span> level of the <strong>Music Performance in Bands</strong> Ofqual qualification.`}
        ] 
      }
    }
    return trinity_progress_low;
  }

  if (stage_progress_percent > 33 && stage_progress_percent < 65) {
    const trinity_progress_medium = {
      "order": ordering.trinity_enrolled,
      "name": "trinity_progress_medium",
      "tracking": "trinity_progress_medium",
      "colorOption": 2,
      "sections": {
        1: [
          {"title-strong-alt": 'Way to go!'}
        ],
        2: [],
        3: [
          {"progress": stage_progress_percent},
        ],
        4: [],
        5: [
          {"body": `<span>${kid_name}</span> is already half way through their <span>${stage}</span> level of the <strong>Music Performance in Bands</strong> Ofqual-regulated qualification.`}
        ] 
      }
    }
    return trinity_progress_medium;
  }

  if (stage_progress_percent >= 65) {
    const trinity_progress_high = {
      "order": ordering.trinity_enrolled,
      "name": "trinity_progress_high",
      "tracking": "trinity_progress_high",
      "colorOption": 2,
      "sections": {
        1: [
          {"title-strong-alt": 'Keep it going!'}
        ],
        2: [],
        3: [
          {"progress": stage_progress_percent},
        ],
        4: [],
        5: [
          {"body": `<span>${kid_name}</span> has very nearly completed their <span>${stage}</span> level of the <strong>Music Performance in Bands</strong> Ofqual qualification.`}
        ] 
      }
    }
    return trinity_progress_high;
  }

  return null;

}

export default trinityEnrollmentSlide;
