const mediaSlide = ({latest_band_media, kid_name, ordering}) => {

  if (!kid_name || !latest_band_media) return null;

    const limited = latest_band_media && latest_band_media.length > 0 && latest_band_media.slice(0, 3);
    if (!limited) return null;

    const mediaSlides = [];

    limited.forEach((m, index) => {
      const copy = {
        0: {
          'title': 'Band Legend in Action',
          'subTitle': `Here's <span>${kid_name}</span> rehearsing with their band!`,
          'tracking': `media_image_${m.date}`,
          'colorOption': 1,
        },
        1: {
          'title': 'Igniting their inner spark',
          'subTitle': 'Making progress every lesson!',
          'tracking': `media_image_${m.date}`,
          'colorOption': 1,
        },
        2: {
          'title': 'Music Memories!',
          'subTitle': 'Where bandmates become real mates.',
          'tracking': `media_image_${m.date}`,
          'colorOption': 3,
        }
      }
      
      if (m.media_type === 'photo') {
        const mediaData = {
          "order": ordering[`media_${index + 1}`],
          "name": "media",
          "tracking": copy[index].tracking,
          "colorOption": copy[index].colorOption,
          "sections": {
            1: [
              {"title-large": copy[index].title},
              {"body-highlight": copy[index].subTitle},
            ],
            2: [],
            3: [
              {"image_src": m.photo_url},
              {"report": m.photo_media_submission_id},
              {"body-strong": `Band Rehearsal — ${m.date}`},
            ],
            4: [],
            5: []
          }
        }
        mediaSlides.push(mediaData)
      }

      return null;
    })

    return mediaSlides;

}

export default mediaSlide;
