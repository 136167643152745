import { kidInstrumentAssets } from '../../../utils/RoundUpAssets';

const bandRoleSlide = ({band_name, band_role, kid_name, ordering, instrument}) => {

  if (!band_role || !kid_name || !instrument) return null;
  const asset = kidInstrumentAssets[instrument] && kidInstrumentAssets[instrument].solo

  if (band_name) {
    const band_role_data = {
      "order": ordering.band_role,
      "name": "band_role",
      "tracking": "band_role",
      "colorOption": 1,
      "sections": {
        1: [
          {"title": kid_name},
          {"micro": "is a"},
          {"subTitle-emphasis": band_role},
          {"micro": "in the band..."},
        ],
        2: [],
        3: [
          {"image_src_special": asset},
        ],
        4: [
          {"head-emphasis": band_name}
        ],
        5: []
      }
    };
    return band_role_data;
  } 
  
  const band_role_data_fallback = {
    "order": ordering.band_role,
    "name": "band_role_fallback",
    "tracking": "band_role_fallback",
    "colorOption": 1,
    "sections": {
      1: [
        {"title": kid_name},
        {"micro": "is the"},
        {"subTitle-emphasis": band_role},
        {"micro": "in their band..."},
      ],
      2: [],
      3: [
        {"image_src_special": asset},
      ],
      4: [],
      5: []
    }
  };

  return band_role_data_fallback;
}

export default bandRoleSlide;

