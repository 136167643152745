const skillsCompleteCountSlide = ({skills_completed, kid_name, ordering}) => {

  if (!kid_name) return null;

  if (skills_completed && skills_completed >= 5) {
    const skills_completed_data = {
      "order": ordering.skills_completed,
      "name": "skills_completed",
      "tracking": "skills_completed",
      "colorOption": 1,
      "sections": {
        1: [
          {"body": `<span>${kid_name}</span> has learnt a total of...`},
        ],
        2: [
          {"unit": skills_completed},
          {"measurement": "skills"},
        ],
        3: [],
        4: [],
        5: [
          {"body": "...across the curriculum, in addition to the amazing benefits of being in a band."},
        ] 
      }
    };
    return skills_completed_data;
  } 
  
  const skills_completed_data_fallback = {
    "order": ordering.skills_completed,
    "name": "skills_completed_fallback",
    "tracking": "skills_completed_fallback",
    "colorOption": 1,
    "sections": {
      1: [
        {"body": `<span>${kid_name}</span>'s ready to rock! Rocksteady's curriculum consists of up to...`},
      ],
      2: [
        {"unit": 300},
        {"measurement": "skills"},
      ],
      3: [],
      4: [],
      5: [
        {"body": `...in addition to all the amazing benefits of being in a band.`}
      ] 
    }
  }
  return skills_completed_data_fallback;
}

export default skillsCompleteCountSlide;
