export const instrumentToRole = {
  "bass": "Bassist",
  "vocals": "Vocalist",
  "guitar": "Guitarist",
  "keyboard": "Keyboardist",
  "drums": "Drummer"
}

export const concertName = {
  "spring": "Easter",
  "summer": "Summer",
  "autumn": "Christmas"
}
