import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import { useSwipeable } from 'react-swipeable';
import { useParams, Link, Redirect } from 'react-router-dom';
import { ReactSVG } from "react-svg";
import PropTypes from 'prop-types';
import paths from '../../consts/paths';
import Audio from './Audio';
import RoundUpAPI from '../../api/RoundupApi';
import GrownUpAPI from '../../api/GrownUpAPI';
import Stepper from './Stepper';
import logo from './assets/logo_white.svg';
import exit from './assets/icon_exit_white.svg';
import mute_on from './assets/icon_mute_on.svg';
import mute_off from './assets/icon_mute_off.svg';
import background_green from './assets/background_green.jpg';
import background_teal from './assets/background_teal.jpg';
import background_yellow from './assets/background_yellow.jpg';
import WindowUtils from '../../utils/WindowUtils';
import Slides from './Slides';
import audioTrack from './assets/practice_makes_perfect.mp3';
import JWTManager from '../../utils/JWTManager';
import './Roundup.scss';
import BugsnagClient from '../../utils/BugsnagClient';
import preloadAsset from '../../utils/AssetUtils';


const RoundUp = ({ location }) => {
  const token = JWTManager.getToken();
  if (token) {
    const releaseLatestVersion = process.env.REACT_APP_PHASE_4_ROUNDUP === 'true';
    const enableComingSoon = !releaseLatestVersion;

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [data, setData] = useState(null);
    const [roundUpId, setRoundUpId] = useState(null);
    const [slideTotal, setSlideTotal] = useState(0);
    const roundupRef = useRef();
    const { kid_id } = useParams();
    const [grownUpId, setGrownUpId] = useState(null);
    const [trackingId, setTrackingId] = useState(null);
    const [trackingSetUpLoading, setTrackingSetUpLoading] = useState(false);
    const [lastTrackingSlideIndex, setLastTrackingSlideIndex] = useState(0);
    const [slideTrackingData, setSlideTrackingData] = useState(null);
    const [firstPlay, setFirstPlay] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);
    const [kidInstrument, setKidInstrument] = useState(null);

    const [playAudioTrack, setPlayAudioTrack] = useState(false);
    const [pauseAudioTrack, setPauseAudioTrack] = useState(false)
    const [fadeAudioTrack, setFadeAudioTrack] = useState(false);
    const [mutedAudioTrack, setMutedAudioTrack] = useState(true);

    const showSlides = data && grownUpId && kid_id;

    const currentSlideColorOption = (data && activeSlideIndex) ? data[activeSlideIndex].colorOption : 1;

    let containerClass = 'Roundup';
    let backgroundImage = background_green;
    switch (currentSlideColorOption) {
      case 1:
        containerClass = 'Roundup';
        backgroundImage = background_green;
        break;
      case 2:
        containerClass = 'RoundupColorOption2';
        backgroundImage = background_yellow;
        break;
      case 3:
        containerClass = 'RoundupColorOption3';
        backgroundImage = background_teal;
        break;
      default:
        return containerClass;
    }

    useEffect(() => {
      // PRELOAD ASSETS
      const imgGreenBackground = new Image();
      const imgYellowBackground = new Image();
      const imgTealBackground = new Image();
      imgGreenBackground.src = background_green;
      imgYellowBackground.src = background_yellow;
      imgTealBackground.src = background_teal;

      preloadAsset(logo);
      preloadAsset(exit);
      preloadAsset(mute_on);
      preloadAsset(mute_off);

      preloadAsset(audioTrack, true);
    }, [])

    // TRACKING - setup
    const trackingSetUp = async () => {
      const getTrackingId = await RoundUpAPI.postRoundUpViewing(slideTrackingData, grownUpId, roundUpId)
      setTrackingId(getTrackingId.id);
      setTrackingSetUpLoading(false);
    }

    // TRACKING - update (slide changed)
    const trackingUpdate = async (slide_designator, slide_number) => {
      return RoundUpAPI.updateRoundUpViewing(trackingId, slide_designator, slide_number)
    }

    const triggerTracking = () => {
      const newIndex = activeSlideIndex + 1;
      // only trigger tracking if slide is higher than tracking already recorded
      if (newIndex > lastTrackingSlideIndex) {
        const { slide_designators } = slideTrackingData;
        const slideName = slide_designators[newIndex];
        trackingUpdate(slideName, activeSlideIndex + 2) // plus 2 as BE not using index value here
        setLastTrackingSlideIndex(newIndex);
      }
    }

    // CONTENT DATA - get
    const getRoundupData = async () => {
      return new Promise((resolve) => {
        RoundUpAPI.getRoundUp(kid_id)
        .then((response) => {
          if (response) {
            const { id, slides, tracking, instrument } = response;
            setRoundUpId(id);
            setData(slides);
            setSlideTrackingData(tracking)
            setKidInstrument(instrument);
          }
          setDataLoading(false)
          resolve();
        })
        .catch(error => {
          // This is here to try and track down some suspicious looking
          // 403 Errors we are getting in production
          BugsnagClient.notify(error, {
            metaData: {
              props: {
                grownUpId,
                kid_id
              }
            }
          });
          setDataLoading(false)
        })
      })
    }

    // HIDE CHAT WIDGET
    useEffect(() => {
      // Create a new mutation observer
      /* eslint-disable-next-line */
      const observer = new MutationObserver(() => {
        const launcher = document.getElementById('launcher');
        const widget = launcher?.parentNode;
        if (widget) {
          widget.style.cssText = 'display: none';
          // Once the widget is found and hidden, we can stop observing for changes
          observer.disconnect();
        }
      });
      // Start observing the document with the configured parameters
      observer.observe(document, { childList: true, subtree: true });
      // Clean up the observer when the component is unmounted
      return () => observer.disconnect();
    }, []);


    // EXIT - redirect to timeline
    const triggerClose = () => {
      WindowUtils.replaceLocation(paths.TIMELINE(kid_id))
    }

    const fetchGrownUpFromToken = async () => {
      if (!grownUpId) {
        return new Promise((resolve, reject) => {
          GrownUpAPI.fetchGrownUpFromToken()
          .then((response) => {
            const { id } = response.data.data;
            setGrownUpId(id);
            setDataLoading(false)
            resolve();
          })
          .catch(error => {
            reject(error)
            setDataLoading(false)
          })
        })
      }
      setDataLoading(false)
      return null;
    }

    useEffect(() => {
      setDataLoading(true)
      fetchGrownUpFromToken()
    }, [grownUpId])

    // Fetch the round-up data
    useEffect(() => {
      if (!data && kid_id && grownUpId) {
        setDataLoading(true)
        getRoundupData()
      }
    }, [data, kid_id, grownUpId])

    // Set up tracking
    useEffect(() => {
      if (data) {
        setSlideTotal(data.length);
        if (!trackingId && !trackingSetUpLoading && slideTrackingData && grownUpId && roundUpId) {
          setTrackingSetUpLoading(true)
          trackingSetUp()
        }
      }
    }, [data, trackingId, grownUpId, roundUpId, slideTrackingData, trackingSetUpLoading])

    // AUDIO
    const onToggleSound = () => {
      setMutedAudioTrack(!mutedAudioTrack)
    }

    const triggerAudioTrackPause = (value) => {
      setPauseAudioTrack(value)
    }

    // CHANGE SLIDE (cta, stepper or swipe)
    const triggerStepChange = (slideIndex) => {
      setActiveSlideIndex(slideIndex)

      if (slideIndex === (data.length - 1) && !mutedAudioTrack) {
        setFadeAudioTrack(true);
      }
    }

    // START - initial cta clicked
    const onStartFunc = () => {
      setPlayAudioTrack(true);
      if (firstPlay) {
        setMutedAudioTrack(false);
        setFirstPlay(false);
        triggerTracking()
      }
      triggerStepChange(1);
    }

    // REFETCH - is triggered by user if they are on the error view
    const onRetry = () => {
      if (!grownUpId) {
        setDataLoading(true)
        fetchGrownUpFromToken()
      }

      if (kid_id) {
        setDataLoading(true)
        getRoundupData()
      }
    }


    // SWIPING - left
    const handleLeftSwipe = () => {
      const allow = activeSlideIndex !== 0 && (activeSlideIndex - 1 > -1);
      if (allow) {
        triggerStepChange(activeSlideIndex -1)
      }
    }


    // SWIPING - right
    const handleRightSwipe = () => {
      const allow = activeSlideIndex !== slideTotal && (activeSlideIndex + 1 <= (slideTotal - 1));
      if (allow) {
        const startSlide = activeSlideIndex === 0 && firstPlay;
        if (!startSlide) {
          triggerStepChange(activeSlideIndex + 1)
        }
        triggerTracking()
      }
    }

    // FIRST SLIDE CLICK (fallback fix)
    const onSlideClickFunc = () => {
      if (data && slideTrackingData && firstPlay) {
        onStartFunc()
      }
    }

    // SET UP SWIPING
    const handlers = useSwipeable({
      onSwipedLeft: () => handleRightSwipe(),
      onSwipedRight: () => handleLeftSwipe()
    });

    // SET UP SWIPING
    const refPassthrough = (el) => {
      handlers.ref(el);
      roundupRef.current = el;
    }

    // BOTTOM NAV (prev and next)
    const renderNavControls = () => {
      const firstOrLast = activeSlideIndex === 0 || activeSlideIndex === (data.length -1);

      if (firstOrLast) return null;
      return (
        <div className="Roundup__navControls">
          <button type='button' onClick={handleLeftSwipe} className={`${containerClass}__navControls__prev`}>
            <span>‹</span>
            Prev
          </button>
          <button type='button' onClick={handleRightSwipe} className={`${containerClass}__navControls__next`}>
            Next
            <span>›</span>
          </button>
        </div>
      )
    }

    // TOP NAV (exit, mute and logo)
    const renderControls = () => {
      let muteButtonClass = (showSlides && !dataLoading) ? `${containerClass}__controls__mute` : 'SlideError__controls__mute';
      if (firstPlay) {
        muteButtonClass = (showSlides && !dataLoading) ? `${containerClass}__controls__hidden` : 'SlideError__controls__hidden';
      }

      return (
        <div className={`${containerClass}__controls`}>
          <button type='button' aria-label='exit' onClick={triggerClose} className={`${containerClass}__controls__exit`}>
            <ReactSVG src={exit} />
          </button>
          <ReactSVG className={`${containerClass}__controls__logo`} src={logo} />
          <button type='button' onClick={onToggleSound} aria-label='mute_button' className={muteButtonClass}>
            <ReactSVG src={mutedAudioTrack ? mute_on : mute_off} />
          </button>
        </div>
      )
  }

    // LOADER
    const renderLoader = () => {
      return (
        <div className="SlideLoading">
          <Loader size="massive" active className="SlideLoading__loader" />
        </div>
      )
    }

    const disableGradient = currentSlideColorOption === 2;
    const gradientBackground = { backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), url(${backgroundImage})`, backgroundBlendMode: 'hard-light, normal' };
    const gradientBackgroundNormal = { backgroundImage: `url(${backgroundImage})`, backgroundBlendMode: 'normal' }
    const backgroundStyle = (activeSlideIndex % 2 && !disableGradient) ? gradientBackground : gradientBackgroundNormal;

    return (
      <div
        className={containerClass}
        style={backgroundStyle}
      >
        <div className={`${containerClass}__wrapper`} {...handlers} ref={refPassthrough}>
          {renderControls()}
          {/* eslint-disable-next-line */}
          <div className={`${containerClass}__slides`} onClick={onSlideClickFunc}>
            {enableComingSoon && (
              <div className="SlideError">
                <p>Spring Rocksteady Round-up, coming soon!</p>
                <button type='button' onClick={triggerClose} className="SlideError__button">
                  Back to Timeline
                </button>
              </div>
            )}
            {!enableComingSoon && showSlides && !dataLoading && releaseLatestVersion && (
              <Slides
                activeIndex={activeSlideIndex}
                slidesData={data}
                colorOption={currentSlideColorOption}
                grownUpId={grownUpId}
                kidId={kid_id}
                muted={mutedAudioTrack}
                triggerAudioTrackPause={triggerAudioTrackPause}
                kidInstrument={kidInstrument}
              />
            )}
            {dataLoading && renderLoader()}
            {!enableComingSoon && !showSlides && !dataLoading && (
              <div className="SlideError">
                <p>Oops, something went wrong.</p>
                <button type='button' onClick={onRetry} className="SlideError__button">
                  Refresh
                </button>
              </div>
            )}
          </div>
          {!enableComingSoon && audioTrack && showSlides && (
            <Audio
              play={playAudioTrack}
              track={audioTrack}
              fade={fadeAudioTrack}
              muted={mutedAudioTrack}
              pause={pauseAudioTrack}
            />
          )}
          {!enableComingSoon && showSlides && !dataLoading && <Stepper activeStep={activeSlideIndex} stepCount={data.length} triggerChangeFunc={triggerStepChange} colorOption={currentSlideColorOption} />}
          {!enableComingSoon && showSlides && renderNavControls()}
          {!enableComingSoon && showSlides && !dataLoading && activeSlideIndex === 0 && (
            <div className={`${containerClass}__button__start`}>
              <button type='button' id='start' onClick={onStartFunc}>
                Tap to start
                <span> ›</span>
              </button>
            </div>
          )}
          {!enableComingSoon && activeSlideIndex === (slideTotal - 1) && (
            <div className={`${containerClass}__button__end`}>
              <button type='button' onClick={triggerClose}>
                <Link to={paths.TIMELINE(kid_id)}>
                  Go to Backstage
                  <span> ›</span>
                </Link>
              </button>

            </div>
          )}
        </div>
      </div>
    )
  }
  return <Redirect to={`${paths.SIGN_IN}?previousPath=${location.pathname + location.search}`} />
}

RoundUp.propTypes = {
  location: PropTypes.object.isRequired
}

export default RoundUp;
