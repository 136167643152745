import { checkLength } from "../RoundUpDataLayoutHelper";
import { kidInstrumentAssets } from '../../../utils/RoundUpAssets';

const introSlide = ({date, band_role, kid_name, ordering, instrument}) => {

  if (!date || !band_role || !kid_name || !instrument) return null;

  const lengthOfName = kid_name.length;
  const title_class = lengthOfName < 10 ? `title-large_typing${checkLength(lengthOfName)}` : "title_typing-large";
  const intro_data = {
    "order": ordering.intro,
    "name": "intro",
    "tracking": "intro",
    "colorOption": 1,
    "sections": {
      1: [
        {[title_class]: `${kid_name}'s`},
        {"title-strong": date},
        {"subTitle": 'Half-term'},
        {"subTitle-emphasis-alt": "Round-up"}
      ],
      2: [],
      3: [
        {"image_src_sparkles": kidInstrumentAssets[instrument].solo},
        {"big_icon": true}
      ],
      4: [], 
      5: [
        {"body": `Catch up on <span>${kid_name}'s</span>  awesome progress and achievements so far...`},
      ]
    }
  };
  return intro_data;
}

export default introSlide;
