import React, { useState, useEffect } from 'react';
import { Segment, List, Icon, Grid, Loader } from 'semantic-ui-react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import paths from '../../consts/paths';
import KidAPI from '../../api/KidAPI';
import PageHeading from '../PageHeading/PageHeading';
import WindowUtils from '../../utils/WindowUtils';

export default function TimetableView({ attributes, kidId }) {
  const [timetableData, setTimetableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const orderObjects = response => {
    response.sort(
      (a, b) =>
        new Moment(a.date).format('YYYYMMDD') -
        new Moment(b.date).format('YYYYMMDD')
    );
    return response;
  };

  async function fetchTimetableData() {
    const { data } = await KidAPI.getRehearsalsAllDayEvents(kidId);
    const arr = orderObjects(data);

    setTimetableData(arr);
    setLoading(false)
  }

  useEffect(() => {
    fetchTimetableData()
    WindowUtils.scrollToTop();

    return () => {
      setTimetableData([]);
      setLoading(true)
    }
  }, [kidId]);

  const rehearsalIcon = () => (
    <List.Icon name="music" size="large" verticalAlign="middle" />
  );

  const allDayEventIcon = isConcert => (
    <List.Icon
      name={isConcert ? 'volume up' : 'info circle'}
      size="large"
      verticalAlign="middle"
    />
  );

  const listItemIcon = item =>
    item.type === 'rehearsal' ? rehearsalIcon() : allDayEventIcon(item.concert);

  const listItemDateAndTime = item => (
    <p>
      {`${Moment(item.date).format('ddd DD MMMM YYYY')} ${
        item.time ? `- ${item.time}` : ''
      }`}
    </p>
  );

  const rehearsalNote = () => <p className="noteHeading">Lesson</p>;

  const parentalAttendanceIndicator = item => {
    if (['school_only', 'concert_video'].includes(item.event_type)) {
      return (
        <p style={{ marginTop: 10 }}>
          <Icon name="close" color="red" />
          Parents cannot attend.
        </p>
      );
    }

    return (
      <p style={{ marginTop: 10 }}>
        <Icon name="checkmark" color="green" />
        Parents can attend.
      </p>
    );
  };

  const concertNote = item => (
    <div>
      <p className="noteHeading" style={{ marginBottom: 0 }}>
        {item.event_type === 'concert_video'
          ? 'Concert Video Session'
          : 'Concert'}
      </p>
      {item.note}
      {parentalAttendanceIndicator(item)}
    </div>
  );

  const allDayEventNote = item => {
    if (item.concert) {
      return concertNote(item);
    }
    return <p className="noteHeading">{item.note}</p>;
  };

  const listItemNote = item =>
    item.type === 'rehearsal' ? rehearsalNote() : allDayEventNote(item);

  return (
    <div className="page-container">
      <PageHeading
        heading={`Timetable for ${attributes.name}`}
        subHeading={(
          <span>
            Please&nbsp;
            <a href={paths.SUPPORT_REQUEST} target="_blank" rel="noopener noreferrer">contact us</a>
            &nbsp;if you notice any errors in school holiday dates
          </span>
        )}
      />
      <Segment>
        <List divided relaxed>
          {
            loading && (
              <Grid.Row centered>
                <Loader active inline size='large'>
                  Fetching timetable for&nbsp;
                  {attributes.name}
                </Loader>
              </Grid.Row>
            )
          }
          {!loading && !timetableData.length
            ? 'We have no Current Timetable Information for you'
            : timetableData.map((item, index) => (
              <List.Item key={`tt${index + 1}`}>
                {listItemIcon(item)}
                <List.Content>
                  <List.Item>{listItemDateAndTime(item)}</List.Item>
                  <List.Item>{listItemNote(item)}</List.Item>
                </List.Content>
              </List.Item>
              ))}
        </List>
      </Segment>
    </div>
  );
}

TimetableView.propTypes = {
  attributes: PropTypes.object.isRequired,
  kidId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
