import { kidInstrumentAssets } from '../../../utils/RoundUpAssets';

const outroSlide = ({instrument, kid_name, ordering}) => {

  if (!kid_name || !instrument) return null;
  const asset = kidInstrumentAssets[instrument] && kidInstrumentAssets[instrument].solo

    const outroData = {
      "order": ordering.outro,
      "name": "outro",
      "tracking": "outro",
      "colorOption": 3,
      "sections": {
        1: [
          {"head-small-emphasis": "Keep rocking..."},
          {"title-large_typing": kid_name}
        ],
        2: [],
        3: [
          {"image_src_sparkles": asset},
          {"big_icon": true}
        ],
        4: [],
        5: [
          {"body": `Such amazing progress so far! Keep an eye out for the next Half-term Round-up.`}
        ]
      }
    };
    return outroData;
}

export default outroSlide;
