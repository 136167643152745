import { skillListItemClassSuffix } from "../RoundUpDataLayoutHelper";

const skillsInProgressSlide = ({skills_in_progress, kid_name, ordering}) => {

  if (!kid_name) return null;

  if (skills_in_progress && skills_in_progress.length > 0) {
    const first = skills_in_progress[0] ? skills_in_progress[0] : '';
    const second = skills_in_progress[1] ? skills_in_progress[1] : '';
    const third = skills_in_progress[2] ? skills_in_progress[2] : '';

    const first_class = first.length > 1 ? `body-strong_star${skillListItemClassSuffix(first)}` : "body-strong";
    const second_class = second.length > 1 ? `body-strong-alt_star${skillListItemClassSuffix(second)}` : "body-strong-alt";
    const third_class = third.length > 1 ? `body-strong_star${skillListItemClassSuffix(third)}` : "body-strong";

    const skills_in_progress_data_specific = {
      "order": ordering.skills_in_progress,
      "name": "skills_in_progress",
      "tracking": "skills_in_progress",
      "colorOption": 1,
      "sections": {
        1: [
          {"title-large": "Skills"},
          {"head-small": "In Progress"},
          {"body-highlight": `<span>${kid_name}</span> has been working on these cool things this half-term...`},
        ],
        2: [],
        3: [],
        4: [
          {[first_class]: `${first}`},
          {[second_class]: `${second}`},
          {[third_class]: `${third}`},
        ],
        5: [
          {"subTitle-emphasis-2": `Keep going!`}
        ] 
      }
    };
    return skills_in_progress_data_specific;

  }

  const skills_in_progress_data_fallback = {
    "order": ordering.skills_in_progress,
    "name": "skills_in_progress_fallback",
    "tracking": "skills_in_progress_fallback",
    "colorOption": 1,
    "sections": {
      1: [
        {"title-large": "Skills"},
        {"body-highlight": `Every lesson, <span>${kid_name}</span> will develop new skills in the following musical areas...`},
      ],
      2: [],
      3: [],
      4: [
        {"body-strong_star": `Instrument`},
        {"body-strong-alt_star": `Song`},
        {"body-strong_star": `Musicianship`},
        {"body-strong-alt_star": `Band`},
      ],
      5: [
        {"subTitle-emphasis-2 ": `Way to go!`}
      ]
    }
  };
  return skills_in_progress_data_fallback;

}

export default skillsInProgressSlide;
