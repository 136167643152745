import { kidInstrumentAssets } from '../../../utils/RoundUpAssets';

const lessonAttendanceSlide = ({lessons_count, kid_name, ordering, instrument}) => {
  if (!lessons_count || !instrument || !lessons_count || !lessons_count > 0 || !kid_name) return null;
  const asset = kidInstrumentAssets[instrument] && kidInstrumentAssets[instrument].band

  const lesson_attendance_data = {
    "order": ordering.lesson_attendance,
    "name": "lesson_attendance",
    "tracking": "lesson_attendance",
    "colorOption": 1,
    "sections": {
      1: [
        {"body": `<span>${kid_name}</span> has spent a total of...`},
      ],
      2: [],
      3: [
        {"image_src_special": asset},
      ],
      4: [
        {"unit": lessons_count * 30},
        {"measurement": "minutes"},
      ],
      5: [
        {"body": "...in band lessons since starting Rocksteady."}
      ] 
    }
  };

  return lesson_attendance_data;
}

export default lessonAttendanceSlide;
