import { kidInstrumentAssets } from '../../../utils/RoundUpAssets';

const upcomingConcertSlide = ({next_concert_planned, nextConcertName, instrument, kid_name, ordering}) => {

  if (!kid_name || !instrument) return null;
  const asset = kidInstrumentAssets[instrument] && kidInstrumentAssets[instrument].concert

  if (next_concert_planned && nextConcertName) {
    const upcoming_concert_data = {
      "order": ordering.upcoming_concert,
      "name": "upcoming_concert",
      "tracking": "upcoming_concert",
      "colorOption": 3,
      "sections": {
        1: [],
        2: [
          {"title-large": `${nextConcertName} concert`},
        ],
        3: [
          {"image_src_sparkles": asset},
        ],
        4: [
          {"head-small-highlight": `It's happening!`},
        ],
        5: [
          {"body": `<span>${kid_name}</span> is performing at the ${nextConcertName} concert soon. We can't wait to see you there!`}
        ] 
      }
    };
    return upcoming_concert_data;
  } 
  
  if (nextConcertName) {
    const upcoming_concert_data = {
      "order": ordering.upcoming_concert,
      "name": "upcoming_concert_fallback",
      "tracking": "upcoming_concert_fallback",
      "colorOption": 3,
      "sections": {
        1: [],
        2: [
          {"title-large": `Rocksteady concert`},
        ],
        3: [
          {"image_src_sparkles": asset},
        ],
        4: [
          {"head-small-highlight": "Coming soon!"},
        ],
        5: [
          {"body": `<span>${kid_name}</span> will be performing at a concert soon — we'll let you know details when it's booked.`}
        ] 
      }
    };
    return upcoming_concert_data;
  }

  return null;
}

export default upcomingConcertSlide;
