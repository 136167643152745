import trinity_info_thumbnail from '../../../components/RoundUp/assets/trinity_info_video.png';

const trinityInfoSlide = ({trinity_enrolled, kid_name, ordering}) => {

  if (!kid_name || !trinity_enrolled) return null;

  const trinity_info_data = {
    "order": ordering.trinity_info,
    "name": "trinity_info",
    "tracking": "trinity_info",
    "colorOption": 2,
    "sections": {
      1: [
        {"head-small": 'Want to know more?'},
      ],
      2: [
        {"body": "It's a first of its kind, exam-free award..."},
      ],
      3: [
        {"video": 'https://vimeo.com/870571499/f1690e22e2', "thumbnail_src": trinity_info_thumbnail},
      ],
      4: [],
      5: [
        {"body": `When <span>${kid_name}</span> has achieved enough skills, their certificate will land in your inbox and they will automatically move on to the next stage of the qualification!`}
      ] 
    }
  };
  return trinity_info_data;
  }

export default trinityInfoSlide;
