import { kidInstrumentAssets } from '../../../utils/RoundUpAssets';

const concertAttendanceSlide = ({concerts_count, kid_name, ordering, instrument}) => {

  if (!kid_name || !instrument) return null;
  const asset = kidInstrumentAssets[instrument] && kidInstrumentAssets[instrument].concert
  if (concerts_count && concerts_count > 0) {
    const concert_attendance_data = {
      "order": ordering.concert_attendance,
      "name": "concert_attendance",
      "tracking": "concert_attendance",
      "colorOption": 1,
      "sections": {
        1: [
          {"body": `<span>${kid_name}</span> has performed in...`},
        ],
        2: [
          {"unit": concerts_count},
          {"measurement": `concert${concerts_count > 1 ? 's' : ''}`},
        ],
        3: [
          {"image_src_sparkles": asset},
        ],
        4: [
          {"head-small": "Fantastic!"},
        ],
        5: [
          {"body": "Regular concerts build children’s confidence, teamwork and listening skills, which can help them back in the classroom."}
        ] 
      }
    };
   return concert_attendance_data;
  } 

  const concert_attendance_data_fallback = {
    "order": ordering.concert_attendance,
    "name": "concert_attendance_fallback",
    "tracking": "concert_attendance_fallback",
    "colorOption": 1,
    "sections": {
      1: [
        {"body": `<span>${kid_name}</span> is currently working towards their...`},
      ],
      2: [
        {"unit": "First"},
        {"measurement": "concert"},
      ],
      3: [
        {"image_src_sparkles": asset},
      ],
      4: [
        {"head-small": "Fantastic!"},
      ],
      5: [
        {"body": "Regular concerts build children’s confidence, teamwork and listening skills, which can help them back in the classroom."}
      ]
    }
  };
  return concert_attendance_data_fallback;
}

export default concertAttendanceSlide;
