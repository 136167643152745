export const skillListItemClassSuffix = (skillDescription) => {
  let suffix = '__small';

  if (skillDescription.length > 168) {
    suffix = '__huge';
  } else if (skillDescription.length > 136) {
    suffix = '__large';
  } else if (skillDescription.length > 46) {
    suffix = '__medium';
  }

  return suffix;
};

export const checkLength = (length) => {
  let styleAddition = '';
  // 2 characters are for the 's
  if (length < 4) {
    styleAddition = '__larger';
  }
  if (length > 3 && length < 6) {
    styleAddition = '__medium';
  }
  return styleAddition;
}
