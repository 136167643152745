import song from '../../../components/RoundUp/assets/song.svg';

const currentSongSlide = ({song_title, song_artist, kid_name, ordering}) => {

  if (!kid_name) return null;

  if (song_title && song_artist) {
    const current_song_data = {
      "order": ordering.current_song,
      "name": "current_song",
      "tracking": "current_song",
      "colorOption": 1,
      "sections": {
        1: [
          {"body": `<span>${kid_name}</span> has been learning...`},
        ],
        2: [
          {"subTitle-emphasis-alt-2": song_artist},
          {"head-emphasis-alt": song_title},
        ],
        3: [
          {"image_src": song},
        ],
        4: [],
        5: [
          {"body": "What a tune!"}
        ] 
      }
    };
    return current_song_data;
  } 
  
  const current_song_fallback_data = {
    "order": ordering.current_song,
    "name": "current_song_fallback",
    "tracking": "current_song_fallback",
    "colorOption": 1,
    "sections": {
      1: [],
      2: [
        {"head-emphasis": kid_name},
        {"body": `will soon pick a song to learn in their band...`},
      ],
      3: [
        {"image_src": song},
      ],
      4: [],
      5: [
        {"body": "...from hundreds of songs they'll love!"}
      ] 
    }
  }
  return current_song_fallback_data;
}

export default currentSongSlide;
